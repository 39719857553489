<style lang="scss" scoped>

</style>
<template>
    <div>
        <vehicle-service-form type="0"></vehicle-service-form>
    </div>
</template>

<script type="text/ecmascript-6">
import vehicleServiceForm from "../components/form/index"

export default {
    name:'vehicleApply',
    //定义模版数据
    data() {
        let data
        return {
            data
        }
    },
    components: {
        vehicleServiceForm
    }
}
</script>

